import Axios from "axios";
import {mCourseTel} from "./elements";
export const isMobile = () => {
    let agent = navigator.userAgent;
    return !!agent.match(/AppleWebKit.*Mobile.*/);
};
function checkPhone(phone: string) {
    const phoneCheck = /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/;
    return phoneCheck.test(phone);

}
function postMail(name: string, phone: string, address: string, desc: string, code: string):Promise<any> {
    return new Promise<any>((resolve, reject) => {
        if(name.trim() === ''){
            reject("【联系人】不能为空");
        } else if(phone.trim() === ''){
            reject("【联系人电话】不能为空");
        } else if(!checkPhone(phone)) {
            reject("【联系人电话格式错误】")
        } else if (address.trim() === '') {
            reject("【合作场地】不能为空")
        } else if(code.trim() === "") {
            reject("【验证码】不能为空")
        }else {
            Axios({
                method: 'POST',
                url: '/mail',
                data: {
                    name: name,
                    phone: phone,
                    address: address,
                    desc: desc,
                    code:code
                }
            }).then((ret)=>{
                let obj = ret.data;
                if(obj.error) {
                    reject(obj.msg);
                }
                resolve(obj.msg)
            }).catch((e)=>{
                reject(e);
            })
        }
    })
}
function postJoin(name: string, phone: string, p: string, desc: string, code: string):Promise<any> {
    return new Promise<any>((resolve, reject) => {
        if(name.trim() === ''){
            reject("【申请人】不能为空");
        } else if(phone.trim() === ''){
            reject("【联系电话】不能为空");
        } else if(!checkPhone(phone)) {
            reject("【联系电话格式错误】")
        } else if (p.trim() === '') {
            reject("【申请职位】不能为空")
        } else if(code.trim() === "") {
            reject("【验证码】不能为空")
        }else {
            Axios({
                method: 'POST',
                url: '/join',
                data: {
                    name: name,
                    phone: phone,
                    p: p,
                    desc: desc,
                    code:code
                }
            }).then((ret)=>{
                let obj = ret.data;
                if(obj.error) {
                    reject(obj.msg);
                }
                resolve(obj.msg)
            }).catch((e)=>{
                reject(e);
            })
        }
    })
}
function postVerify(name: string, phone: string):Promise<any> {
    return new Promise<any>((resolve, reject) => {
        if(name.trim() === ''){
            reject("【联系人】不能为空");
        } else if(phone.trim() === ''){
            reject("【联系人电话】不能为空");
        } else if(!checkPhone(phone)) {
            reject("【联系人电话格式错误】")
        } else {
            Axios({
                method: 'POST',
                url: '/send-code',
                data: {
                    phone: phone,
                }
            }).then((data)=>{
                let d = data.data;
                if(d > 0) {
                    resolve(data.data)
                } else {
                    reject("当天最多发送5次，请明天再试")
                }
            }).catch((e)=>{
                reject(e);
            })
        }
    })
}
function postJoinVerify(name: string, phone: string):Promise<any> {
    return new Promise<any>((resolve, reject) => {
        if(name.trim() === ''){
            reject("【联系人】不能为空");
        } else if(phone.trim() === ''){
            reject("【联系人电话】不能为空");
        } else if(!checkPhone(phone)) {
            reject("【联系人电话格式错误】")
        } else {
            Axios({
                method: 'POST',
                url: '/send-join-code',
                data: {
                    phone: phone,
                }
            }).then((data)=>{
                let d = data.data;
                if(d > 0) {
                    resolve(data.data)
                } else {
                    reject("当天最多发送5次，请明天再试")
                }
            }).catch((e)=>{
                reject(e);
            })
        }
    })
}
export interface APIResult {
    success?: boolean,
    data?: any,
    msg?: string
}

async function postAgent(courseId: string, courseName: string, user: string, phone: string, email: string): Promise<APIResult> {
    try {
        let ret = await Axios({
            method: 'POST',
            url: '/agent',
            data: {
                courseId,
                courseName,
                user,
                phone,
                email
            }
        });
        return ret.data;
    } catch (e) {
        console.error(e);
        return {
            success: false,
            data: null,
            msg: e.message
        }
    }
}
export const PostMail = postMail;
export const PostJoin = postJoin;
export const PostAgent = postAgent;
export const PostVerify = postVerify;
export const PostJoinVerify = postJoinVerify;
