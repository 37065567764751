export class MobileMenuController {
    $menuButton:HTMLElement;
    $menuView:HTMLElement;
    isShow: boolean;
    $header:HTMLElement;
    $home:HTMLElement;
    $app:HTMLElement;
    $bike:HTMLElement;
    $data: HTMLElement;
    $signed: HTMLElement;
    $service: HTMLElement;
    $agent: HTMLElement;
    $about: HTMLElement;
    $concat: HTMLElement;
    $container:HTMLElement;
    $news: HTMLElement;
    target:number;

    constructor(btn:HTMLElement, view: HTMLElement){
        this.$menuButton = btn;
        this.$menuView = view;
        this.init();
    }
    init(){
        this.isShow = false;
        this.target = 0;
        this.$header = document.getElementsByTagName('header')[0];
        this.$home = document.getElementById('home');
        this.$app = document.getElementById('app');
        this.$bike = document.getElementById('bike');
        this.$data = document.getElementById('data');
        this.$signed = document.getElementById('signed');
        this.$service = document.getElementById('service');
        this.$concat = document.getElementById('concat-page');
        this.$agent = document.getElementById('agent-page');
        this.$about = document.getElementById('about');
        this.$news = document.getElementById('news');
        this.$container = document.getElementById('page-container');
        this.$menuView.children.item(0).classList.add('show');
        this.$menuView.style.height = this.$container.offsetHeight+"px";
        this.bindShow();
        this.bindMenu();
    }
    bindShow() {
        this.$menuButton.onclick = ()=>{
            this.switchShow();
        }
    }
    switchShow() {
        this.isShow = !this.isShow;
        if(this.isShow){
            this.$menuButton.classList.add("show");
            this.$menuView.classList.add("show");
        } else {
            this.$menuButton.classList.remove("show");
            this.$menuView.classList.remove('show');
        }
    }
    bindMenu() {
        let menu = this.$menuView.children;
        for(let i=0; i<menu.length; i++){
            let $menuItem = menu.item(i) as HTMLElement;
            $menuItem.ontouchend = ()=>{
                let old = this.target;
                this.target = i;
                menu.item(old).classList.remove('show');
                $menuItem.classList.add("show");
                this.switchShow();
                this.scrollMenu(i);
                console.log("scroll", i)
            }

        }
    }
    scrollMenu(index:number){
        switch (index){
            case 0:
                this.$container.scrollTop = this.$home.offsetTop;
                break;
            case 1:
                this.$container.scrollTop = this.$bike.offsetTop;
                break;
            case 2:
                this.$container.scrollTop = this.$app.offsetTop;
                break;
            case 3:
                this.$container.scrollTop = this.$data.offsetTop;
                break;
            case 4:
                this.$container.scrollTop = this.$signed.offsetTop;
                break;
            case 5:
                this.$container.scrollTop = this.$news.offsetTop;
                break;
            case 6:
                this.$container.scrollTop =this.$about.offsetTop;
                break;
            case 7:
                this.$container.scrollTop =this.$about.offsetTop;
                break;
            case 8:
                this.$container.scrollTop = this.$about.offsetTop;
                break;

            default:
                break;
        }
    }
}
