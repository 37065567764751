import './style.scss';
import {MouseWheelEvent} from "./mouse-wheel";
import {
    isMobile, PostAgent, PostJoin, PostJoinVerify,
    PostMail, PostVerify
} from "./utils";
import {PageController} from "./controllers/pageController";
import {AppController} from "./controllers/appController";
import {BikeController} from "./controllers/bikeController";
import {MobileMenuController} from "./controllers/mobileMenuController";
import {MobileBikeController} from "./controllers/mobileBikeController";
import {
    addInput,
    addInputM,
    bikeList,
    body,
    cancelAgentDesk,
    cancelM,
    closeBtn,
    container,
    course,
    courseName,
    descInput,
    descInputM,
    descs,
    deskAgent,
    deskAgentWin,
    deskConcat,
    deskConcatClose,
    deskConcatWin,
    deskError,
    email,
    errorCourse,
    errorMail,
    errorTel,
    errorUser,
    imgs,
    InitElements,
    joinUS,
    joinWin,
    label,
    list,
    listDown,
    listItems,
    main,
    mCourseList,
    menuBtn,
    menuView,
    mError,
    nameInput,
    nameInputM,
    navs,
    pages,
    partList,
    phoneInput,
    phoneInputM,
    submitAgentDesk,
    submitButton,
    submitM,
    tel,
    totalError,
    trigs,
    user,
    verifyCode,
    verifyCodeM,
    verifySend,
    verifySendM,
    mJoinVerify,
    mjError,
    mJoinName,
    mJoinTel,
    mJoinSubmit,
    mJoinP,
    mJoinCode,
    mJoinDesc,
    mJoinCancel,
    deskJoin,
    deskJoinView,
    deskJoinClose,
    deskJoinVerify,
    deskJoinSubmit,
    redirectBtn
} from "./elements";
import {MobileAgentController} from "./controllers/mobileAgentController";
import {verify} from "crypto";
import Timeout = NodeJS.Timeout;
import {doCancelJoinView, doJoinCode, doPostJoin} from "./events";

let phoneCheck = /^1(3|4|5|6|7|8|9)\d{9}$/;
let emailCheck = /^[\w.]+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
let isMobileClient = isMobile();
let deskAgentEnable: boolean = true;
let doCancelDeskAgent = function() {
    totalError.classList.remove('success', 'error');
    course.innerText='';
    if(course.attributes['courseid']) {
        course.attributes.removeNamedItem('courseid');
    }
    user.value = '';
    tel.value = '';
    email.value = '';
    errorCourse.classList.remove('show');
    errorUser.classList.remove('show');
    errorTel.classList.remove('show');
    errorMail.classList.remove('show');
    deskAgentWin.classList.remove('show');
};
let doSubmitDeskAgent = function () {
    if(!deskAgentEnable) {
        return;
    } else {
        totalError.classList.remove('success', 'error');
        deskAgentEnable = false;
        if(!submitAgentDesk.classList.contains('disabled')) {
            submitAgentDesk.classList.add('disabled');
        }
    }
    let error: boolean = false;
    if(!course.attributes['courseid'] || course.attributes['courseid'].value === '') {
        errorCourse.innerText = '请选择代理课程';
        if(!errorCourse.classList.contains('show')) {
            errorCourse.classList.add('show');
        }
        error = true;
    } else {
        errorCourse.classList.remove('show');
    }
    if(!user.value || user.value === '') {
        errorUser.innerText = '请输入您的姓名';
        if(!errorUser.classList.contains('show')) {
            errorUser.classList.add('show');
        }
        error = true;
    } else {
        errorUser.classList.remove('show');
    }
    if(!tel.value || tel.value === '') {
        errorTel.innerText = '请输入电话号码';
        if(!errorTel.classList.contains('show')) {
            errorTel.classList.add('show');
        }
        error = true;
    } else {
        if(!phoneCheck.test(tel.value)) {
            errorTel.innerText = '电话号码格式错误';
            if(!errorTel.classList.contains('show')) {
                errorTel.classList.add('show');
            }
            error = true;
        } else {
            errorTel.classList.remove('show');
        }
    }
    if(!email.value || email.value === '') {
        errorMail.innerText = '请输入联系邮箱';
        if(!errorMail.classList.contains('show')) {
            errorMail.classList.add('show');
        }
        error = true;
    } else {
        if(!emailCheck.test(email.value)) {
            errorMail.innerText = '邮箱地址格式错误';
            if(!errorMail.classList.contains('show')) {
                errorMail.classList.add('show');
            }
            error = true;
        } else {
            errorMail.classList.remove('show');
        }
    }
    if(error) {
        deskAgentEnable = true;
        if(submitAgentDesk.classList.contains('disabled')) {
            submitAgentDesk.classList.remove('disabled');
        }
    } else {
        PostAgent(course.attributes['courseid'].value, course.innerText, user.value, tel.value, email.value).then((res)=>{
            if(!res.success) {
                if(res.data) {
                    if(res.data.phone !== '') {
                        errorTel.innerText = res.data.phone;
                        if(!errorTel.classList.contains('show')) {
                            errorTel.classList.add('show');
                        }
                    }
                    if(res.data.email !== '') {
                        errorMail.innerText = res.data.email;
                        if(!errorMail.classList.contains('show')) {
                            errorMail.classList.add('show');
                        }
                    }
                } else {
                    totalError.innerText = res.msg;
                    totalError.classList.remove('success', 'error');
                    totalError.classList.add('error');
                }
            } else {
                totalError.innerText = '恭喜您！成功提交课程代理申请！';
                totalError.classList.remove('success', 'error');
                totalError.classList.add('success');
            }
            deskAgentEnable = true;
            if(submitAgentDesk.classList.contains('disabled')) {
                submitAgentDesk.classList.remove('disabled');
            }
        }).catch(e=>{
            console.error(e);
            totalError.innerText = e.message;
            totalError.classList.remove('success', 'error');
            totalError.classList.add('error');
            deskAgentEnable = true;
            if(submitAgentDesk.classList.contains('disabled')) {
                submitAgentDesk.classList.remove('disabled');
            }
        })
    }
};
window.addEventListener('click', function (e: Event) {
    try{
        let list = document.getElementById('course-list');
        if(list?.classList.contains('show')) {
            list.classList.remove('show');
        }
        if(mCourseList?.classList.contains('show')) {
            mCourseList.classList.remove('show');
        }
    } catch (e) {
        console.error(e);
    }
});
let secondCount:Timeout;
let closeConcat = function() {
    nameInput.value = "";
    phoneInput.value = '';
    addInput.value = "";
    descInput.value = "";
    verifyCode.value = "";
    deskConcatWin.classList.remove('show');
    deskError.innerText = "";
    deskError.classList.remove('show');
};
window.addEventListener('load', function () {
    InitElements();
    body.style.display = "flex";

    let postDisabled = false;
    let codeDisabled = false;
    let joinCodeDisabled = false;
    let joinPostDisabled = false;
    if(isMobileClient){
        body.classList.add('mobile');
        new MobileMenuController(menuBtn, menuView);
        new MobileBikeController(bikeList);
        redirectBtn.onclick = ()=>{
            location.href = "weixin://dl/business/?t=FFIN730vmrf";
        }
        submitM.onclick = ()=>{
            if(postDisabled){return}
            mError.classList.remove('success');
            mError.classList.remove('show');
            mError.innerText = "";
            postDisabled = true;
            submitM.classList.add('disabled');
            let addValue = addInputM.selectedOptions[0].value;
            console.log(addValue);
            PostMail(nameInputM.value, phoneInputM.value, addValue, descInputM.value, verifyCodeM.value).then(()=>{
                nameInputM.value = '';
                phoneInputM.value = '';
                addInputM.value = '';
                descInputM.value = '';
                verifyCodeM.value="";
                submitM.classList.remove('disabled');
                mError.classList.add('success');
                mError.innerText = "提交成功!";
                postDisabled = false;
            }).catch(e=>{
                mError.classList.add('show');
                mError.innerText = e;
                submitM.classList.remove('disabled');
                postDisabled = false
            })
        };
        verifySendM.onclick = ()=>{
            if(codeDisabled){return}
            mError.classList.remove('show');
            mError.innerText = '';
            codeDisabled = true;
            verifySendM.classList.add('disabled');
            PostVerify(nameInputM.value, phoneInputM.value).then((num)=>{
                let start = num;
                secondCount = setInterval(()=>{
                    let minutes = Math.floor(start / 60);
                    let seconds = start - minutes * 60;
                    verifySendM.innerText = `${minutes > 0 ? minutes+"分": ""}${seconds}秒后重发`;
                    start--;
                    if(start <= 0) {
                        verifySendM.innerText = "发送验证码";
                        clearInterval(secondCount);
                        verifySendM.classList.remove('disabled');
                        codeDisabled = false;
                    }
                }, 1000)
            }).catch(e=>{
                mError.classList.add('show');
                mError.innerText = e;
                verifySendM.classList.remove('disabled');
                codeDisabled = false;
            })
        };
        cancelM.onclick = ()=>{
            nameInputM.value = '';
            phoneInputM.value = '';
            addInputM.value = '';
            descInputM.value = '';
            verifyCodeM.value='';
            mError.classList.remove('show');
            mError.innerText = '';
        };
        mJoinSubmit.onclick = ()=>{
            if(joinPostDisabled){return}
            mjError.classList.remove('success');
            mjError.classList.remove('show');
            mjError.innerText = "";
            joinPostDisabled = true;
            mJoinSubmit.classList.add('disabled');
            let addValue = mJoinP.selectedOptions[0].value;
            console.log("join-", addValue);
            PostJoin(mJoinName.value, mJoinTel.value, addValue, mJoinDesc.value, mJoinCode.value).then(()=>{
                mJoinName.value='';
                mJoinTel.value='';
                mJoinP.value ='';
                mJoinDesc.value='';
                mJoinCode.value='';
                mJoinSubmit.classList.remove('disabled');
                mjError.classList.add('success');
                mjError.innerText = "提交成功!";
                joinPostDisabled = false;
            }).catch(e=>{
                console.error(e);
                mjError.classList.add('show');
                mjError.innerText = e;
                mJoinSubmit.classList.remove("disabled");
                joinPostDisabled = false;
            })
        }

        mJoinVerify.onclick = ()=>{
            if(joinCodeDisabled){return}
            mjError.classList.remove('show');
            mjError.innerText = '';
            joinCodeDisabled = true;
            mJoinVerify.classList.add('disabled');
            PostJoinVerify(mJoinName.value, mJoinTel.value).then(num=>{
                let start = num;
                secondCount = setInterval(()=>{
                    let minutes = Math.floor(start / 60);
                    let seconds = start - minutes * 60;
                    mJoinVerify.innerText = `${minutes > 0 ? minutes+"分": ""}${seconds}秒后重发`;
                    start--;
                    if(start <= 0) {
                        mJoinVerify.innerText = "发送验证码";
                        clearInterval(secondCount);
                        mJoinVerify.classList.remove('disabled');
                        joinCodeDisabled = false;
                    }
                }, 1000)
            }).catch(e=>{
                mjError.classList.add('show');
                mjError.innerText = e;
                mJoinVerify.classList.remove('disabled');
                joinCodeDisabled = false;
            })
        }
        mJoinCancel.onclick = ()=>{
            mJoinName.value='';
            mJoinTel.value='';
            mJoinP.value ='';
            mJoinDesc.value='';
            mJoinCode.value='';
            mjError.classList.remove('show');
            mjError.innerText = '';
        }
        MobileAgentController.init();
    } else {
        body.classList.add('desktop');
        let pageController = new PageController(main, container, pages,
            navs,800, 800);
        let appController = new AppController(imgs, descs, trigs, 6000);

        new BikeController(label, partList);
        appController.loopStart();
        MouseWheelEvent( document.getElementsByTagName('body')[0], ()=>{
            console.log('123');
            pageController.pageUp();

        }, ()=>{
            pageController.pageDown();
        });
        joinUS.onclick = ()=>{
            joinWin.classList.add('show');
        };
        closeBtn.onclick = ()=>{
            joinWin.classList.remove('show');
        };
        let showCourseList = function(e: Event) {
            if(!list.classList.contains('show')) {
                list.classList.add('show');
            }
            e.stopPropagation();
        };
        for(let i=0; i<listItems.length; i++) {
            let item = <HTMLElement> listItems.item(i);
            item.onclick = ()=>{
                courseName.innerText = item.innerText;
                courseName.setAttribute('courseid', item.attributes['courseid'].value);
            }
        }
        (<HTMLElement>listItems.item(0)).click();
        listDown.onclick = showCourseList;
        courseName.onclick = showCourseList;
        submitAgentDesk.onclick = doSubmitDeskAgent;
        cancelAgentDesk.onclick = doCancelDeskAgent;

        deskConcat.onclick = ()=>{
            doCancelDeskAgent();
            doCancelJoinView();
            if(!deskConcatWin.classList.contains('show')) {
                deskConcatWin.classList.add('show');
            }
        };
        deskAgent.onclick = ()=>{
            closeConcat();
            doCancelJoinView();
            if(!deskAgentWin.classList.contains('show')) {
                deskAgentWin.classList.add('show');
            }
        };
        deskJoin.onclick = ()=>{
            doCancelDeskAgent();
            closeConcat();
            if(!deskJoinView.classList.contains('show')) {
                deskJoinView.classList.add('show');
            }
        }
        deskConcatClose.onclick = closeConcat;
        submitButton.onclick = ()=>{
            if(postDisabled){return}
            deskError.classList.remove('show');
            deskError.innerText = '';
            postDisabled = true;
            let addValue = addInput.selectedOptions[0].value;
            console.log(addValue);
            submitButton.classList.add('disabled');
            PostMail(nameInput.value, phoneInput.value, addValue, descInput.value, verifyCode.value).then((ret)=>{
                verifyCode.value=""
                nameInput.value = "";
                phoneInput.value = '';
                addInput.value = "";
                descInput.value = "";
                submitButton.classList.remove('disabled');
                postDisabled = false;
                deskConcatWin.classList.remove('show');
            }).catch(e=>{
                deskError.classList.add('show');
                deskError.innerText = e;
                submitButton.classList.remove('disabled');
                postDisabled = false;
            })
        };
        verifySend.onclick = ()=>{
            if(codeDisabled){return}
            deskError.classList.remove('show');
            deskError.innerText = '';
            codeDisabled = true;
            verifySend.classList.add('disabled');
            PostVerify(nameInput.value, phoneInput.value).then((num)=>{
                let start = num;
                secondCount = setInterval(()=>{
                    let minutes = Math.floor(start / 60);
                    let seconds = start - minutes * 60;
                    verifySend.innerText = `${minutes > 0 ? minutes+"分": ""}${seconds}秒后重发`;
                    start--;
                    if(start <= 0) {
                        verifySend.innerText = "发送验证码";
                        clearInterval(secondCount);
                        verifySend.classList.remove('disabled');
                        codeDisabled = false;
                    }
                }, 1000)
            }).catch(e=>{
                deskError.classList.add('show');
                deskError.innerText = e;
                verifySend.classList.remove('disabled');
                codeDisabled = false;
            })
        }
        deskJoinClose.onclick = doCancelJoinView;
        deskJoinVerify.onclick = doJoinCode;
        deskJoinSubmit.onclick = doPostJoin;
    }
});



