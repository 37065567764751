import {
    mAgentCourse, mAgentError,
    mAgentListDown, mCourseAgentReset, mCourseAgentSubmit, mCourseEmail, mCourseEmailError, mCourseError,
    mCourseList, mCourseTel, mCourseTelError, mCourseUser, mCourseUserError
} from "../elements";
import {PostAgent} from "../utils";
const phoneCheck = /^1(3|4|5|6|7|8|9)\d{9}$/;
const emailCheck = /^[\w.]+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
export class MobileAgentController {
    private static deskAgentEnable: boolean;
    private static showCourseList(e: Event) {
        if(!mCourseList.classList.contains('show')) {
            mCourseList.classList.add('show');
        }
        e.stopPropagation();
    }
    public static init() {
        this.deskAgentEnable = true;
        mAgentListDown.onclick = this.showCourseList.bind(this);
        mAgentCourse.onclick = this.showCourseList.bind(this);
        let mListItems: HTMLCollection = mCourseList.children;
        for(let i=0; i<mListItems.length; i++) {
            let item = <HTMLElement> mListItems.item(i);
            item.onclick = ()=>{
                mAgentCourse.value = item.innerText;
                mAgentCourse.setAttribute('courseid', item.attributes['courseid'].value);
            }
        }
        (<HTMLElement>mListItems.item(0)).click();
        mCourseAgentSubmit.onclick = this.doSubmit.bind(this);
        mCourseAgentReset.onclick = this.doReset.bind(this);
    }
    private static doReset() {
        mAgentError.classList.remove('success', 'error');
        mAgentCourse.innerText='';
        if(mAgentCourse.attributes['courseid']) {
            mAgentCourse.attributes.removeNamedItem('courseid');
        }
        mCourseUser.value = '';
        mCourseTel.value = '';
        mCourseEmail.value = '';
        mCourseError.classList.remove('show');
        mCourseUserError.classList.remove('show');
        mCourseTelError.classList.remove('show');
        mCourseEmailError.classList.remove('show');
    }
    private static doSubmit() {
        if(!this.deskAgentEnable) {
            return;
        } else {
            mAgentError.classList.remove('success', 'error');
            this.deskAgentEnable = false;
            if(!mCourseAgentSubmit.classList.contains('disabled')) {
                mCourseAgentSubmit.classList.add('disabled');
            }
        }
        let error: boolean = false;
        if(!mAgentCourse.attributes['courseid'] || mAgentCourse.attributes['courseid'].value === '') {
            mCourseError.innerText = '请选择代理课程';
            if(!mCourseError.classList.contains('show')) {
                mCourseError.classList.add('show');
            }
            error = true;
        } else {
            mCourseError.classList.remove('show');
        }
        if(!mCourseUser.value || mCourseUser.value === '') {
            mCourseUserError.innerText = '请输入您的姓名';
            if(!mCourseUserError.classList.contains('show')) {
                mCourseUserError.classList.add('show');
            }
            error = true;
        } else {
            mCourseUserError.classList.remove('show');
        }
        if(!mCourseTel.value || mCourseTel.value === '') {
            mCourseTelError.innerText = '请输入电话号码';
            if(!mCourseTelError.classList.contains('show')) {
                mCourseTelError.classList.add('show');
            }
            error = true;
        } else {
            if(!phoneCheck.test(mCourseTel.value)) {
                mCourseTelError.innerText = '电话号码格式错误';
                if(!mCourseTelError.classList.contains('show')) {
                    mCourseTelError.classList.add('show');
                }
                error = true;
            } else {
                mCourseTelError.classList.remove('show');
            }
        }
        if(!mCourseEmail.value || mCourseEmail.value === '') {
            mCourseEmailError.innerText = '请输入联系邮箱';
            if(!mCourseEmailError.classList.contains('show')) {
                mCourseEmailError.classList.add('show');
            }
            error = true;
        } else {
            if(!emailCheck.test(mCourseEmail.value)) {
                mCourseEmailError.innerText = '邮箱地址格式错误';
                if(!mCourseEmailError.classList.contains('show')) {
                    mCourseEmailError.classList.add('show');
                }
                error = true;
            } else {
                mCourseEmailError.classList.remove('show');
            }
        }
        if(error) {
            this.deskAgentEnable = true;
            if(mCourseAgentSubmit.classList.contains('disabled')) {
                mCourseAgentSubmit.classList.remove('disabled');
            }
        } else {
            PostAgent(mAgentCourse.attributes['courseid'].value, mAgentCourse.value, mCourseUser.value, mCourseTel.value, mCourseEmail.value).then((res)=>{
                if(!res.success) {
                    if(res.data) {
                        if(res.data.phone !== '') {
                            mCourseTelError.innerText = res.data.phone;
                            if(!mCourseTelError.classList.contains('show')) {
                                mCourseTelError.classList.add('show');
                            }
                        }
                        if(res.data.email !== '') {
                            mCourseEmailError.innerText = res.data.email;
                            if(!mCourseEmailError.classList.contains('show')) {
                                mCourseEmailError.classList.add('show');
                            }
                        }
                    } else {
                        mAgentError.innerText = res.msg;
                        mAgentError.classList.remove('success', 'error');
                        mAgentError.classList.add('error');
                    }
                } else {
                    mAgentError.innerText = '恭喜您！成功提交课程代理申请！';
                    mAgentError.classList.remove('success', 'error');
                    mAgentError.classList.add('success');
                }
                this.deskAgentEnable = true;
                if(mCourseAgentSubmit.classList.contains('disabled')) {
                    mCourseAgentSubmit.classList.remove('disabled');
                }
            }).catch(e=>{
                console.error(e);
                mAgentError.innerText = e.message;
                mAgentError.classList.remove('success', 'error');
                mAgentError.classList.add('error');
                this.deskAgentEnable = true;
                if(mCourseAgentSubmit.classList.contains('disabled')) {
                    mCourseAgentSubmit.classList.remove('disabled');
                }
            })
        }
    }
}
