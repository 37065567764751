export class MobileBikeController{
    $bikeList: HTMLCollection;
    bikeIndex: number;
    constructor(list: HTMLCollection){
        this.$bikeList = list;
        this.init();
    }
    init(){
        this.bikeIndex = 0;
        this.$bikeList.item(0).classList.add('show');
        for(let i=0; i<this.$bikeList.length; i++) {
            let item = this.$bikeList.item(i) as HTMLElement;
            item.ontouchend = ()=>{
                this.switchBike(i);
            }
        }
    }
    switchBike(index:number){
        if(index < 0 || index >= this.$bikeList.length || index === this.bikeIndex) return;
        let item = this.$bikeList.item(index);
        let old = this.bikeIndex;
        this.bikeIndex = index;
        let oldItem = this.$bikeList.item(old);
        oldItem.classList.remove('show');
        item.classList.add('show');
    }
}
