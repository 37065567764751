export let body;
export let container ;
export let main ;
export let pages ;
export let navs ;
export let slider ;
export let triggerGroup ;
export let label ;
export let partGroup ;
export let partList ;
export let imgs ;
export let descs ;
export let trigs ;
export let joinUS ;
export let closeBtn ;
export let joinWin ;
export let menuBtn ;
export let menuView ;
export let bikeList ;
export let deskConcat ;
export let deskAgent;
export let deskConcatWin ;
export let deskAgentWin;
export let deskConcatClose ;
export let listDown ;
export let list ;
export let listItems: HTMLCollection ;
export let courseName ;
export let nameInput ;
export let nameInputM ;
export let phoneInput ;
export let phoneInputM ;
export let addInput ;
export let addInputM ;
export let descInput ;
export let descInputM ;
export let deskError ;
export let mError ;
export let submitButton ;
export let submitM ;
export let cancelM ;
export let submitAgentDesk ;
export let cancelAgentDesk;
export let course ;
export let user ;
export let tel ;
export let email ;
export let errorCourse ;
export let errorUser ;
export let errorTel ;
export let errorMail ;
export let totalError ;
export let mAgentError;
export let mAgentCourse;
export let mAgentListDown;
export let mCourseList;
export let mCourseError;
export let mCourseUser: HTMLInputElement;
export let mCourseUserError;
export let mCourseTel: HTMLInputElement;
export let mCourseTelError;
export let mCourseEmail: HTMLInputElement;
export let mCourseEmailError;
export let mCourseAgentSubmit;
export let mCourseAgentReset;
export let verifyCode: HTMLInputElement;
export let verifySend;
export let verifyCodeM:HTMLInputElement;
export let verifySendM;
export let mjError;
export let mJoinName;
export let mJoinTel ;
export let mJoinCode ;
export let mJoinVerify ;
export let mJoinP ;
export let mJoinDesc ;
export let mJoinSubmit ;
export let mJoinCancel ;

export let deskJoin;
export let deskJoinView;
export let deskJoinClose;
export let deskJoinError;
export let deskJoinName;
export let deskJoinTel;
export let deskJoinCode;
export let deskJoinVerify;
export let deskJoinP;
export let deskJoinDesc;
export let deskJoinSubmit;

export let redirectBtn;

function initElements() {
    body = document.getElementsByTagName('body')[0];
    container = document.getElementById('page-container');
    main = document.getElementById('main');
    pages = document.getElementsByClassName('page');
    navs = document.getElementsByClassName('nav-item');
    slider = document.getElementById('info-slider');
    triggerGroup = document.getElementById('info-slider-trigger');
    label = document.getElementById('part-name');
    partGroup = document.getElementById('part-imgs');
    partList = partGroup.children;
    imgs = slider.children[0].children;
    descs = slider.children[1].children;
    trigs = triggerGroup.children;
    joinUS = document.getElementById('join-us');
    closeBtn = document.getElementById('close-button');
    joinWin = document.getElementById('app-qcode-mask');
    menuBtn = document.getElementById('menu');
    menuView = document.getElementById('menu-view');
    bikeList = document.getElementById("bike-mobile-parts").children;
    deskConcat = document.getElementById("concat");

    deskAgent = document.getElementById("course-agent");
    deskConcatWin = document.getElementById('desk-concat-mask');
    deskAgentWin = document.getElementById("desk-agent-mask");
    deskConcatClose = document.getElementById('cancel');
    //join items
    deskJoin = document.getElementById("join");
    deskJoinView = document.getElementById("desk-join-mask");
    deskJoinClose = document.getElementById("join-cancel");
    deskJoinError = document.getElementById("desk-error-join");
    deskJoinName = document.getElementById("join-name");
    deskJoinTel = document.getElementById("join-tel");
    deskJoinCode = document.getElementById("join-code");
    deskJoinVerify = document.getElementById("join-verify");
    deskJoinP = document.getElementById("join-p");
    deskJoinDesc = document.getElementById("join-desc");
    deskJoinSubmit = document.getElementById("join-submit");
    //join items end
    listDown = document.getElementById('down');
    list = document.getElementById('course-list');
    listItems = list.children;
    courseName = document.getElementById('course');
    nameInput = document.getElementById('name') as HTMLInputElement;
    nameInputM = document.getElementById('name-m') as HTMLInputElement;
    phoneInput = document.getElementById('tel') as HTMLInputElement;
    phoneInputM = document.getElementById('tel-m') as HTMLInputElement;
    addInput = document.getElementById('address') as HTMLInputElement;
    addInputM = document.getElementById('address-m') as HTMLInputElement;
    descInput = document.getElementById('desc') as HTMLInputElement;
    descInputM = document.getElementById('desc-m') as HTMLInputElement;
    deskError = document.getElementById('desk-error-mail');
    mError = document.getElementById('mobile-error-mail');
    mjError=document.getElementById('mobile-join-error-mail');
    //mobile join
    mJoinName = document.getElementById('join-name-m');
    mJoinTel = document.getElementById('join-tel-m');
    mJoinCode = document.getElementById('code-m-join');
    mJoinVerify = document.getElementById('verify-m-join');
    mJoinP = document.getElementById('join-p-m');
    mJoinDesc = document.getElementById('join-desc-m');
    mJoinSubmit = document.getElementById("submit-m-join");
    mJoinCancel = document.getElementById("cancel-m-join");
    //mobile join end
    submitButton = document.getElementById('submit');
    submitM = document.getElementById('submit-m');
    cancelM = document.getElementById('cancel-m');
    submitAgentDesk = document.getElementById('submit-course');
    cancelAgentDesk = document.getElementById('cancel-course');
    course = document.getElementById('course');
    user = <HTMLInputElement>document.getElementById('course-user');
    tel = <HTMLInputElement>document.getElementById('course-tel');
    email = <HTMLInputElement>document.getElementById('course-email');
    errorCourse = document.getElementById('desk-error-agent-course');
    errorUser = document.getElementById('desk-error-agent-user');
    errorTel = document.getElementById('desk-error-agent-tel');
    errorMail = document.getElementById('desk-error-agent-mail');
    totalError = document.getElementById('desk-agent-error');
    mAgentError = document.getElementById('mobile-agent-error');
    mAgentCourse = document.getElementById('m-course');
    mAgentListDown = document.getElementById('m-down');
    mCourseList = document.getElementById('m-course-list');
    mCourseError = document.getElementById('m-error-agent-course');
    mCourseUser = document.getElementById('m-course-user') as HTMLInputElement;
    mCourseUserError = document.getElementById('m-error-agent-user');
    mCourseTel = document.getElementById('m-course-tel') as HTMLInputElement;
    mCourseTelError = document.getElementById('m-error-agent-tel');
    mCourseEmail = document.getElementById('m-course-email') as HTMLInputElement;
    mCourseEmailError = document.getElementById('m-error-agent-mail');
    mCourseAgentSubmit = document.getElementById('submit-agent-m');
    mCourseAgentReset = document.getElementById('cancel-agent-m');
    verifyCode = document.getElementById('code') as HTMLInputElement;
    verifySend = document.getElementById("verify");
    verifyCodeM = document.getElementById('code-m') as HTMLInputElement;
    verifySendM = document.getElementById("verify-m");
    redirectBtn = document.getElementById("redirect-btn");
}
export const InitElements = initElements;

