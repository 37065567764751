import {
    deskJoinCode,
    deskJoinDesc,
    deskJoinError,
    deskJoinName,
    deskJoinP, deskJoinSubmit,
    deskJoinTel, deskJoinVerify,
    deskJoinView
} from "./elements";
import {PostJoin, PostJoinVerify} from "./utils";
import Timeout = NodeJS.Timeout;
let joinCodeDisabled = false;
let joinPostDisabled = false;
let secondCount:Timeout;
export function doCancelJoinView() {
    deskJoinName.value = "";
    deskJoinTel.value = "";
    deskJoinP.value = "";
    deskJoinDesc.value = "";
    deskJoinCode.value = "";
    deskJoinView.classList.remove("show");
    deskJoinError.innerText = "";
    deskJoinError.classList.remove("show");
}
export function doJoinCode(){
    if(joinCodeDisabled){return}
    deskJoinError.classList.remove('show');
    deskJoinError.innerText = '';
    joinCodeDisabled = true;
    deskJoinVerify.classList.add('disabled');
    PostJoinVerify(deskJoinName.value, deskJoinTel.value).then(num=>{
        let start = num;
        secondCount = setInterval(()=>{
            let minutes = Math.floor(start / 60);
            let seconds = start - minutes * 60;
            deskJoinVerify.innerText = `${minutes > 0 ? minutes+"分": ""}${seconds}秒后重发`;
            start--;
            if(start <= 0) {
                deskJoinVerify.innerText = "发送验证码";
                clearInterval(secondCount);
                deskJoinVerify.classList.remove('disabled');
                joinCodeDisabled = false;
            }
        }, 1000)
    }).catch(e=>{
        deskJoinError.classList.add('show');
        deskJoinError.innerText = e;
        deskJoinVerify.classList.remove('disabled');
        joinCodeDisabled = false;
    })
}
export function doPostJoin(){
    if(joinPostDisabled){return}
    deskJoinError.classList.remove('success');
    deskJoinError.classList.remove('show');
    deskJoinError.innerText = "";
    joinPostDisabled = true;
    deskJoinSubmit.classList.add('disabled');
    let addValue = deskJoinP.selectedOptions[0].value;
    console.log("join-", addValue);
    PostJoin(deskJoinName.value, deskJoinTel.value, addValue, deskJoinDesc.value, deskJoinCode.value).then(()=>{
        doCancelJoinView();
    }).catch(e=>{
        console.error(e);
        deskJoinError.classList.add('show');
        deskJoinError.innerText = e;
        deskJoinSubmit.classList.remove("disabled");
        joinPostDisabled = false;
    })
}
