export class BikeController {
    $label:HTMLElement;
    $partList: HTMLCollection;
    partIndex: number;
    constructor(label: HTMLElement, part: HTMLCollection){
        this.$label = label;
        this.$partList = part;
        this.init();
    }
    init(){
        this.partIndex = 0;
        this.$partList.item(0).classList.add('on');
        this.$label.innerText = this.$partList.item(0).attributes["title"].value;
        for(let i=0; i<this.$partList.length; i++) {
            let $item = <HTMLElement>this.$partList.item(i);
            $item.onmouseenter = ()=>{
                this.moveTo(i);
            }
        }
    }
    moveTo(index:number){
        if(index < 0 || index >= this.$partList.length || index===this.partIndex) return;
        let oldIndex = this.partIndex;
        this.partIndex = index;
        let $item = this.$partList.item(index);
        let $oldItem = this.$partList.item(oldIndex);
        this.$label.innerText = $item.attributes["title"].value;
        $item.classList.add('on');
        $oldItem.classList.remove('on');
    }
}
